<template>
	<resources-index :resources="resources" type="infographic" plural="infographics"></resources-index>
</template>

<script>

import ResourcesIndex from '@/views/resources/Index'
import {sync} from "vuex-pathify";
export default {
	name: "InfographicIndex",
	components: {
		ResourcesIndex
	},
	computed: {
		resources: sync('infographics/infographics'),
	},
}
</script>